import type { ReactQuery } from 'cms-api'
import { range, reduce } from 'lodash'
import { transparentize } from 'polished'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ROUTE } from 'shared-constants'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled, { css } from 'styled-components'
import { getVariantsLeadTime } from '@public/Showroom/Products/Detail/utils'
import { Image } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import { OptionalLink } from 'components/Link'
import { H6, Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import {
  DEFAULT_CURRENCY_ISO_CODE,
  type ProductAvailability,
  PRODUCT_AVAILABILITY,
  PRODUCT_VARIANT_STATUSES,
} from 'constants/common'
import { useExpertShowroomCommission } from 'hooks'
import { useGAListItemView } from 'providers/googleAnalytics/utils/ListView/useGAListItemView'
import { useUserAuth } from 'providers/userAuth'
import {
  BORDER_WIDTH,
  BOX_SHADOW,
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  LINE_HEIGHT,
  RADIUS,
  SPACE,
  Z_INDEX,
} from 'Theme'
import { type ProductVariant } from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { PRICE_UPON_REQUEST, formatPriceRange } from 'utils/formatters'
import { CollectionWidgetCardAction } from './CollectionWidgetCardAction'
import {
  type VariantsType,
  useHandleCollectionWidgetCardClick,
} from './useHandleCollectionWidgetCardClick'

const IMAGE_BASE_WIDTH = 300

const Wrapper = styled(Box)`
  overflow: hidden;
  border: 1px solid ${COLOR_INTENT.COLLECTION_WIDGET_CARD.BORDER};
  background-color: ${COLOR_INTENT.COLLECTION_WIDGET_CARD.BACKGROUND};
  border-radius: ${RADIUS.PX_10};
  box-shadow: ${BOX_SHADOW.ELEVATION_COLLECTION_CARD_1},
    ${BOX_SHADOW.ELEVATION_5};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`

const StyledText = styled(Text)`
  ${({ isLoading, isSoldOut, hasLeadTime, shouldDisplayTradePrice }) =>
    !isLoading &&
    (shouldDisplayTradePrice || (!hasLeadTime && isSoldOut)) &&
    css`
      text-decoration: line-through;
      text-decoration-color: ${COLOR_INTENT.COLLECTION_WIDGET_CARD.PRICE};
    `};
`

const SoldOutBadge = styled(Text)`
  z-index: ${Z_INDEX.SOLD_OUT_BADGE};
  font-size: ${FONT_SIZE.PX_12};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  line-height: ${LINE_HEIGHT.S};
  border-radius: ${RADIUS.PX_4};
  color: ${COLOR_INTENT.COLLECTION_WIDGET_CARD.SOLD_OUT_BADGE.TEXT};
  background-color: ${COLOR_INTENT.COLLECTION_WIDGET_CARD.SOLD_OUT_BADGE
    .BACKGROUND};
  padding: ${SPACE.PX_4} ${SPACE.PX_10};
`

const BlackFridayBadge = styled(Box)`
  z-index: ${Z_INDEX.SOLD_OUT_BADGE};
  font-size: ${FONT_SIZE.PX_13};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  line-height: ${LINE_HEIGHT.S};
  border-radius: ${RADIUS.PX_6};
  color: ${COLOR_INTENT.COLLECTION_WIDGET_CARD.BLACK_FRIDAY_BADGE.TEXT};
  background-color: ${COLOR_INTENT.COLLECTION_WIDGET_CARD.BLACK_FRIDAY_BADGE
    .BACKGROUND};
  border: ${BORDER_WIDTH.PX_1} solid
    ${transparentize(
      0.9,
      COLOR_INTENT.COLLECTION_WIDGET_CARD.BLACK_FRIDAY_BADGE.TEXT
    )};
  padding: ${SPACE.PX_4} ${SPACE.PX_9};
`

const ActionWrapper = styled(Flex)`
  position: absolute;
  top: ${SPACE.PX_8};
  left: ${SPACE.PX_8};
  gap: ${SPACE.PX_8};
`

export const generateDummyProducts = (count: number) =>
  range(count).map((index) => ({
    sku: `dummy${index + 1}`,
    title: 'Placeholder Title',
  }))

interface CollectionWidgetCardProps {
  index: number
  isLoading?: boolean
  title: string
  url?: string
  productImageUrl?: string
  productImageAlt?: string
  variants?: VariantsType[]
  onClick?: () => void
  minPrice?: Pick<ProductVariant['price'], 'total' | 'currencyIsoCode'>
  maxPrice?: Pick<ProductVariant['price'], 'total' | 'currencyIsoCode'>
  tradeMinPrice?: Pick<
    ProductVariant['tradePrice'],
    'total' | 'currencyIsoCode'
  >
  tradeMaxPrice?: Pick<
    ProductVariant['tradePrice'],
    'total' | 'currencyIsoCode'
  >
  isReportingListItemGA?: boolean
  brand?: string
  availability?: ProductAvailability
  isDisabled?: boolean
  displayOptions?: boolean
  collectionExpertSlug?: string
  productId: number
  blackFridayPromoCode?: ReactQuery.PromoCode
}

interface CollectionWidgetCardExtensions {
  Action: typeof CollectionWidgetCardAction
}

const CAROUSEL_IMAGE_WIDTH = SPACE.PX_22

const CarouselImage = styled(Image)`
  border-radius: ${RADIUS.CIRCLE};
  width: ${CAROUSEL_IMAGE_WIDTH};
`

export const CollectionWidgetCard: React.FC<
  React.PWC<CollectionWidgetCardProps>
> &
  CollectionWidgetCardExtensions = ({
  index,
  isLoading: isLoadingProp,
  onClick,
  title,
  productImageUrl,
  productImageAlt,
  variants,
  minPrice,
  maxPrice,
  tradeMinPrice,
  tradeMaxPrice,
  url,
  isReportingListItemGA,
  brand,
  availability,
  children,
  isDisabled,
  displayOptions,
  collectionExpertSlug,
  productId,
  blackFridayPromoCode,
}) => {
  const { user, isPageLoading } = useUserAuth()
  const { MOBILE } = useMedia()

  const intersectionRef = useRef(null)
  useGAListItemView({
    itemRef: intersectionRef,
    reportData: { ...variants?.[0], index },
    isActive: isReportingListItemGA && variants?.length > 0,
  })
  const linkRef = useRef<HTMLDivElement>(null)
  const handleClick = useHandleCollectionWidgetCardClick(
    index,
    variants,
    onClick
  )
  const [imageUrl, setImageUrl] = useState<string>()
  const { addExpertCommission } = useExpertShowroomCommission()

  // Check also if page is loading to avoid flashing content for trade users when trade prices aren't available yet
  const isLoading = isLoadingProp || isPageLoading

  useEffect(() => {
    if (!isLoading) {
      setImageUrl(productImageUrl)
    }
  }, [isLoading, productImageUrl])

  const hasLeadTime = variants?.some((item) =>
    getVariantsLeadTime({
      managedCustomFields: item?.managedCustomFields,
    })
  )

  const previewVariants = variants ?? []

  const breakoutVariants = previewVariants
    // Even thought the request to API asks only for published variants the implementation takes filters into account ONLY
    // for admin role (https://github.com/TheExpertDevelopment/the-expert/pull/6264/files#diff-a93a77d637eee5c14d127ca1ee6c3e78d2a01788e438400d8c52b2c23db7c1e6R19)
    // so we need to filter them out here as well
    .filter(({ status }) => status === PRODUCT_VARIANT_STATUSES.PUBLISHED)
    .map(({ optionValues, id }) => {
      // Note: list of option names needs to keep in sync with apps/node/message-queue/queues/flx/syncPublishedProducts/processor/customFieldValidation/customFields/productCarouselImage.ts
      const validOptionValues = (optionValues ?? []).filter(
        ({ productOption: { name } }) =>
          ['color', 'finish', 'material', 'size'].includes(name)
      )

      return {
        id,
        options: validOptionValues.map(
          ({ productOption: { name }, value }) => ({
            name,
            value,
          })
        ),
      }
    })
    .filter(({ options }) => options.length > 0)

  const uniqueBreakoutVariants = breakoutVariants.reduce((acc, { options }) => {
    acc.add(JSON.stringify(options))
    return acc
  }, new Set<string>())

  const optionsCount = uniqueBreakoutVariants.size ?? 0

  const [priceMin, priceMax] = useMemo(() => {
    if (minPrice !== undefined && maxPrice !== undefined) {
      return [minPrice.total, maxPrice.total]
    }

    return reduce(
      variants,
      ([min, max], { price }) => [
        price?.total > 0 ? Math.min(min, price?.total) : min,
        price?.total > 0 ? Math.max(max, price?.total) : max,
      ],
      [Infinity, 0]
    )
  }, [variants, minPrice, maxPrice])

  const [tradePriceMin, tradePriceMax] = useMemo(() => {
    if (tradeMinPrice !== undefined && tradeMaxPrice !== undefined) {
      return [tradeMinPrice.total, tradeMaxPrice.total]
    }

    return reduce(
      variants,
      ([tradeMin, tradeMax], { tradePrice }) => [
        tradePrice?.total > 0
          ? Math.min(tradeMin, tradePrice?.total)
          : tradeMin,
        tradePrice?.total > 0
          ? Math.max(tradeMax, tradePrice?.total)
          : tradeMax,
      ],
      [Infinity, 0]
    )
  }, [tradeMinPrice, tradeMaxPrice, variants])

  const currency =
    variants?.[0].price?.currencyIsoCode ?? DEFAULT_CURRENCY_ISO_CODE

  const formattedPriceRange = formatPriceRange({
    priceMin,
    priceMax,
    currency,
  })

  const formattedTradePriceRange = formatPriceRange({
    priceMin: tradePriceMin,
    priceMax: tradePriceMax,
    currency,
  })

  const isTradePriceAvailable =
    formattedTradePriceRange !== PRICE_UPON_REQUEST &&
    formattedPriceRange !== formattedTradePriceRange
  const shouldDisplayTradePrice = user?.isTradeCapable && isTradePriceAvailable

  let carouselVariants = previewVariants.filter(
    (variant) => variant.carouselImage
  )

  const leadImageVariant = carouselVariants.find((v) => v.leadImage)

  carouselVariants = [
    ...(leadImageVariant ? [leadImageVariant] : []),
    ...carouselVariants.filter((v) => !v.leadImage),
  ]

  const visibleCarouselVariants = carouselVariants.slice(
    0,
    Math.min(MOBILE ? 3 : 4, carouselVariants.length)
  )

  const handleClickProduct = () => {
    if (collectionExpertSlug) {
      addExpertCommission(collectionExpertSlug, productId)
    }
  }

  const shouldDisplayBlackFridayBadge =
    brand &&
    availability &&
    !isPageLoading &&
    !user?.isTradeCapable &&
    availability !== PRODUCT_AVAILABILITY.SOLD_OUT &&
    blackFridayPromoCode &&
    !blackFridayPromoCode.exclusionBrands?.some(({ name }) => name === brand)

  return (
    <Wrapper
      width="100%"
      height="100%"
      {...(isLoading ? {} : { onClick: handleClick })}
      ref={intersectionRef}
    >
      <OptionalLink
        onClick={handleClickProduct}
        href={url}
        aria-label={linkRef?.current?.textContent ?? ''}
      >
        <Flex
          data-test-class={DATA_TEST_CLASS.SHOWROOM_PRODUCT_CARD}
          position="relative"
          flexDirection="column"
          height="100%"
          width="100%"
          flexShrink={0}
          ref={linkRef}
        >
          <Flex position="relative">
            <Image
              isLoading={isLoading}
              alt={productImageAlt || title}
              objectFit="cover"
              aspectRatio={ASPECT_RATIO.PRODUCTS}
              src={imageUrl}
              baseWidth={IMAGE_BASE_WIDTH}
              isDisabled={isDisabled}
            />
          </Flex>

          <ActionWrapper flexDirection="column">
            <Flex gap={SPACE.PX_8}>
              {!isLoading && availability === PRODUCT_AVAILABILITY.SOLD_OUT && (
                <SoldOutBadge>Out of stock</SoldOutBadge>
              )}
              {shouldDisplayBlackFridayBadge && (
                <BlackFridayBadge>Black Friday Sale</BlackFridayBadge>
              )}
            </Flex>
            {React.Children.count(children) > 0 && (
              <Flex
                flexDirection="column"
                gap={SPACE.PX_8}
                alignItems="flex-start"
              >
                {React.Children.map(children, (child) => {
                  if (!React.isValidElement(child)) {
                    return null
                  }

                  if (child.type !== CollectionWidgetCardAction) {
                    return child
                  }

                  return React.cloneElement(child)
                })}
              </Flex>
            )}
          </ActionWrapper>

          <Flex
            pt={SPACE.PX_16}
            pb={SPACE.PX_10}
            px={MOBILE ? SPACE.PX_12 : SPACE.PX_16}
            flexDirection="column"
            flex={1}
          >
            <H6
              mb={0}
              color={COLOR_INTENT.COLLECTION_WIDGET_CARD.TITLE}
              fontSize={{ MOBILE: FONT_SIZE.PX_14, TABLET: FONT_SIZE.PX_16 }}
              isLoading={isLoading}
            >
              {title}
            </H6>
            {brand && !user?.isTradeCapable && (
              <Text
                mt={SPACE.PX_4}
                fontSize={{
                  MOBILE: FONT_SIZE.PX_11,
                  TABLET: FONT_SIZE.PX_12,
                }}
                color={COLOR_INTENT.SHOWROOM_CARD.BRAND}
                isLoading={isLoading}
              >
                {brand}
              </Text>
            )}
            <Flex mt={SPACE.PX_12} flexDirection="column">
              {shouldDisplayTradePrice && (
                <StyledText
                  color={COLOR_INTENT.COLLECTION_WIDGET_CARD.TRADE_PRICE}
                  mr={SPACE.PX_12}
                  mb={SPACE.PX_4}
                  fontSize={{
                    MOBILE: FONT_SIZE.PX_13,
                    TABLET: FONT_SIZE.PX_14,
                  }}
                  isLoading={isLoading}
                >
                  Trade: {formattedTradePriceRange}
                </StyledText>
              )}
              <StyledText
                fontSize={
                  shouldDisplayTradePrice
                    ? FONT_SIZE.PX_12
                    : {
                        MOBILE: FONT_SIZE.PX_13,
                        TABLET: FONT_SIZE.PX_14,
                      }
                }
                fontFamily={FONT_STACK.SANS}
                fontStyle={shouldDisplayTradePrice ? 'italic' : undefined}
                color={COLOR_INTENT.COLLECTION_WIDGET_CARD.PRICE}
                isLoading={isLoading}
                isSoldOut={availability === PRODUCT_AVAILABILITY.SOLD_OUT}
                hasLeadTime={hasLeadTime}
                shouldDisplayTradePrice={shouldDisplayTradePrice}
              >
                {formattedPriceRange}
              </StyledText>
            </Flex>

            {displayOptions && (
              <Flex
                mt={MOBILE ? SPACE.PX_8 : SPACE.PX_12}
                alignItems="flex-end"
                gap={MOBILE ? SPACE.PX_4 : SPACE.PX_8}
                flex={1}
                mb={SPACE.PX_4}
              >
                <Flex alignItems="center" height={SPACE.PX_26}>
                  {carouselVariants.length > 1 && (
                    <Flex
                      gap={MOBILE ? SPACE.PX_2 : SPACE.PX_4}
                      alignItems="center"
                      mr={SPACE.PX_4}
                    >
                      {visibleCarouselVariants.map(
                        ({ id, product, carouselImage, slug }) => (
                          <OptionalLink
                            key={id}
                            href={ROUTE.SHOWROOM_PRODUCT_DETAIL({
                              slug: product.slug,
                              variantSlug: slug,
                            })}
                            aria-label={linkRef?.current?.textContent ?? ''}
                            height={CAROUSEL_IMAGE_WIDTH}
                          >
                            <CarouselImage
                              baseWidth={22}
                              src={carouselImage?.url}
                              alt={carouselImage?.alt}
                            />
                          </OptionalLink>
                        )
                      )}
                    </Flex>
                  )}

                  {optionsCount >
                    Math.max(1, visibleCarouselVariants.length) && (
                    <Text
                      color={COLOR_INTENT.COLLECTION_WIDGET_CARD.MORE_OPTIONS}
                      fontSize={MOBILE ? FONT_SIZE.PX_9 : FONT_SIZE.PX_10}
                      pt={SPACE.PX_2}
                      fontStyle="italic"
                    >
                      + More options
                    </Text>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </OptionalLink>
    </Wrapper>
  )
}

CollectionWidgetCard.Action = CollectionWidgetCardAction
